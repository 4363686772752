<template>
    <div class="stockInquiry  content-index">
        <div
                class="flex-row"
                style="margin-bottom:10px;cursor:pointer"
                @click="back"
        >
            <i class="el-icon-back" style="font-size:18px;color:#000"></i>
            <span style="padding-left:10px">库存查询</span>
        </div>
        <div class="search">
            <Search :search-data="searchData" @searchChange="searchChange"/>
        </div>
        <div v-loading="tableLoading" class="table-box">
            <Table
                    :button-type="true"
                    :button-width="120"
                    :current-value="formData.current"
                    :header-style="headerStyle"
                    :height="''"
                    :orientation="'center'"
                    :serial-number="true"
                    :table-data="tableData"
                    :title-list="titleListData"
                    :total="total"
                    @handleCurrentChange="handleCurrentChange"
                    @operationClick="operationClick"
                    @textClick="textClick"
            />
        </div>
    </div>
</template>

<script>
import Search from "@/components/search";
import Table from "@/components/table2";
import {getGoodsPage} from "@/api/commodity";

export default {
    name: "StockManagement",
    components: {
        Search,
        Table,
    },
    data() {
        return {
            searchData: [
                {
                    prop: "goodsName",
                    label: "商品名称",
                    type: "text",
                    labelWidth: "70px",
                },
                {
                    labelWidth: "20px",
                    typeList: [
                        {
                            name: "查询",
                            code: "search",
                            type: "primary",
                        },
                        {
                            name: "重置",
                            code: "reset",
                        },
                    ],
                },
            ],
            headerStyle: {
                background: "#f5f7fa",
                height: "50px",
            },
            total: 0,
            tableData: [],
            titleListData: [
                {
                    prop: "goodsName",
                    label: "产品名称",
                },
                {
                    prop: "orderGoodsUnit",
                    label: "单位",
                },
                {
                    prop: "goodsTypeDesc",
                    label: "商品分类",
                },
                {
                    prop: "totalJoinNum",
                    label: "入库总数",
                },
                {
                    prop: "totalOutNum",
                    label: "出库总数",
                },
                {
                    prop: "totalSaleNum",
                    label: "售卖总数",
                    buttonBool: true
                },
                {
                    prop: "occupyInventoryNum",
                    label: "占用库存",
                },
                {
                    prop: "surplusInventoryNum",
                    label: "可用库存",
                },
            ],
            tableLoading: false,
            formData: {
                current: 1,
                size: 10,
                goodsStatus: -1,
                goodsType: 1
            },
            detailsId: "",
        };
    },
    created() {
        this.getGoodsPageList();
    },
    methods: {
        back() {
            this.$router.back();
        },
        // 获取列表数据
        getGoodsPageList() {
            this.tableLoading = true;
            getGoodsPage({...this.formData})
                .then(({data}) => {
                    if (data.code == 0) {
                        this.tableData = data.data.records.map((item) => {
                            item.buttonList = [
                                {
                                    name: "出入库明细",
                                    size: "medium",
                                    type: "text",
                                    code: "details",
                                    width: "120",
                                },
                            ];
                            return item;
                        });
                        this.total = data.data.total;
                    } else {
                        this.$message.error(data.msg);
                    }
                    this.tableLoading = false;
                })
                .catch(() => {
                    this.tableLoading = false;
                });
        },
        // 搜索内容触发
        searchChange({code, formData}) {
            if (code == "search") {
                this.formData = {
                    ...this.formData,
                    current: 1,
                    goodsName: formData.goodsName,
                };
            } else if (code == "reset") {
                this.formData = {
                    current: 1,
                    size: 10,
                    goodsStatus: -1,
                    goodsType: 1
                };
            }
            this.getGoodsPageList();
        },
        // 表格分页触发
        handleCurrentChange(value) {
            this.formData.current = value;
            this.getGoodsPageList();
        },
        // 点击表格按钮触发
        operationClick({row, code}) {
            if (code == "details") {
                this.$router.push({
                    path: "/home/more/IntoAndOutDetails",
                    query: {
                        detailsId: row.goodsId,
                    },
                });
            }
        },
        // 点击行内文字触发
        textClick({data,code}) {
            if (code == "totalSaleNum") {
                this.$router.push({
                    path: "/home/more/sellDetails",
                    query: {
                        detailsId: data.goodsId,
                    },
                });
            }
        }
    },
};
</script>

<style lang="scss" scoped>
.stockInquiry {
  padding: 20px;
  overflow: auto;
}

.search {
  padding-bottom: 10px;
}

.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-row {
  display: flex;
  align-items: center;
}

::v-deep .el-table__body td.el-table__cell {
  height: 50px;
}
</style>
